<template>
  <view-container v-if="chatUserData" name="chat-user-invite">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft"/>
          <span class="custom-header__avatar me-2">
            <a-avatar :size="32" :src="chatUserData.initiator.photo"  >
              <a-icon slot="icon" type="user"/>
            </a-avatar>
          </span>
          {{ chatUserData.initiator.name }}
        </template>
        <template #last>
          <button
              class="btn-icon-t1"
          >
            <svg-icon size="size24x24" name="icoMoreFill"/>
          </button>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div
              data-vue-name="ChatUserInvite"
              class="chat-user-invite layout-grid-2row-revers"
          >

        <div class="chat-box container-fluid">
          <div class="chat__avatar">
            <a-avatar :size="24" :src="chatUserData.initiator.photo"  >
              <a-icon slot="icon" type="user"/>
            </a-avatar>
          </div>
          <div class="chat__message">
            <div class="chat__text">
              Hey!!! I would like to challenge you!
              <svg-icon
                  v-if="chatUserData.competition.kind_sport.name === 'Swimming'"
                  size="size24x24"
                  class-name="ico-swimming me-1"
                  name="ico-swimming"
              />
              <svg-icon
                  v-if="chatUserData.competition.kind_sport.name === 'Basketball'"
                  size="size24x24"
                  class-name="ico-basketball me-1"
                  name="ico-basketball"
              />
              <span v-if="chatUserData.competition.kind_sport.name === 'Swimming'">Swimming</span>
              <span v-if="chatUserData.competition.kind_sport.name === 'Basketball'">Basketball</span>
            </div>
            <div class="chat__opponent-info">
              <div class="d-flex align-items-center mb-1 container-fluid">
                <div class="chat__opponents-avatar">
                  <a-avatar :size="32" :src="chatUserData.initiator.photo"  >
                    <a-icon slot="icon" type="user"/>
                  </a-avatar>
                </div>
                <div class="chat__opponents-name">{{ chatUserData.initiator.name }}</div>
              </div>
              <div class="d-flex align-items-center mb-3 container-fluid">
                <img class="me-1 ico-flag" :src="chatUserData.initiator.country.icon" alt="">
                {{ chatUserData.initiator.country.code }}
                <svg-icon name="icoStar" size="size16x16" class-name="ms-3 me-1" />
                {{chatUserData.initiator.rating}}
              </div>
              <div class="d-flex align-items-center container-fluid mb-2">
                <svg-icon name="icoDatepicker" size="size16x16" class-name="me-1" />
                {{ eventData }}
                <svg-icon name="icoTime" size="size16x16" class-name="ms-2 me-1" />
                {{ eventTime }}
              </div>
              <ul v-if="chatUserData.competition.kind_sport.name === 'Swimming'" class="chat__opponent-detail d-flex">
                <li
                  v-for="(item, key) in kindSportTypes"
                  :key="item"
                >
                  <div>{{ key }}</div>
                  <div>{{ item }}</div>
                </li>
              </ul>
               <div v-if="chatUserData.competition.kind_sport.name === 'Basketball'" class="invite-card__details">
                  <p class="discipline"> Discipline </p>
                  <span class="value"> Around the world </span>
              </div>
              <div
                  class="chat__opponent-footer"
                  @click="goDetailsOpponent"
              >
                More details
                <svg-icon size="size20x20" name="icoMoreBlue" class-name="ms-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </view-content>
  </view-container>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'ChatUserInvite',
  data() {
    return {
      chatUserData: null,
      isNotFoundOpponent: false,
      isLoadingError: false,
      isFetching: false
    }
  },
  computed: {
    eventData() {
      const date = this.chatUserData?.competition.event_at

      if (!date) {
        return
      }

      return moment(date).format('DD MMM YYYY')
    },
    eventTime() {
      const date = this.chatUserData?.competition.event_at

      if (!date) {
        return
      }

      return moment(date).format('hh:mm')
    },
    kindSportTypes() {
      const { types } = this.chatUserData.competition.kind_sport
      const details = types.reduce((acc, item) => {
        if(item.values[0]) acc[item.name] = item.values[0].name
        return acc
      }, {})
      return details
    },
  },
  mounted: async function() {
    this.isFetching = true
    try {
      await this.$store.dispatch('competitions/getInvites')
      const opponent = this.$store.state.competitions.invites.find(item => {
        return item.id === Number(this.$route.params.opponentId)
      })
      if (opponent) {
        this.chatUserData = opponent;
      } else {
        this.isNotFoundOpponent = true
      }

    } catch (e) {
      this.isLoadingError = true
    } finally {
      this.isFetching = false
    }
  },
  methods: {
    goDetailsOpponent() {
      this.$router.push({
        name: 'details',
        params: { id: this.chatUserData.competition.id },
      })
    }
  }
}
</script>

<style lang="stylus">
.chat-user-invite-view {
  .view-content {
    padding 0 16px !important
  }

  .container-fluid {
    padding 0
  }
}


.custom-header__avatar {
  display inline-flex
}
.custom-header__avatar, .chat__opponents-avatar {
  width 32px
  height 32px
  border-radius 50%
  overflow hidden
  flex-shrink 0
  margin-right: 4px
}
.chat__opponents-name {
  text-overflow ellipsis
  overflow hidden
}
.chat-box {
  margin-top: 26px
  display flex
  gap 8px
  align-items flex-start
}
.chat__avatar {
  width 24px
  height 24px
  border-radius 50%
  overflow hidden
}
.chat__avatar img, .custom-header__avatar img, .chat__opponents-avatar img {
  width 100%
  height 100%
  object-fit cover
}
.chat__message .chat__text {
  max-width 230px
  font-weight: 500;
  background: rgba(86, 204, 242, 0.2);
  border-radius: 0 12px 12px 12px;
  padding 8px
  margin-bottom: 8px
}
.chat__opponent-info {
  border: 2px solid #1D2130;
  box-sizing: border-box;
  border-radius: 12px;
  color: #D8D8D8;
  max-width 230px
  overflow hidden
}
.chat__opponent-detail {
  font-size 12px
  gap 10px
  background: #1D2130;
  padding 8px 16px
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.chat__opponent-detail li div:first-child {
  color: #7B8B97;
}
.chat__opponent-footer {
  display flex
  align-items center
  justify-content center
  font-size: 16px;
  line-height: 22px;
  color: #4285F4;
  padding 16px 0
  background: #1D2130;
}
.invite-card__details{
  display flex;
  justify-content: space-between;
  align-items: center
  padding: 8px 8px

  .discipline{
    font-size: 12px;
    line-height: 1;
    color: #7b8b97;
    margin-bottom 0
  }
}
</style>
